import { render, staticRenderFns } from "./Toolbar.vue?vue&type=template&id=0bf90cdb&scoped=true&"
import script from "./script.js?vue&type=script&lang=js&"
export * from "./script.js?vue&type=script&lang=js&"
import style0 from "./Toolbar.vue?vue&type=style&index=0&id=0bf90cdb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bf90cdb",
  null
  
)

export default component.exports