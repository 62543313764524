import gql from 'graphql-tag'

export const GET_ALL_CAMPAINGS = gql` 
query GetAllCampaigns($orderby: String, $offset: Int, $limit: Int, $direction: String, $filter: String) {
  campaigns(orderby: $orderby, offset: $offset, limit: $limit, direction: $direction, filter: $filter) {
    campaign_id
    campaign_name
    campaign_description
    team_id
    account {
      account_id
      account_name
    }
    advertiser {
      account_id
      account_name
    }
    campaign_status {
      status_descr
    }
    team {
      team_id
      team_name
    }
  }
}`

export const GET_ALL_CAMPAINGS_COST = gql` 
query GET_ALL_CAMPAINGS_COST($offset: Int, $limit: Int) {
  campaigns(offset: $offset, limit: $limit) {
    campaign_id
    campaign_name
    advertiser {
      account_id
      account_name
    }
  }
}`

export const GET_ALL_CAMPAINGS_DELIVERY_DAY = gql` 
query GET_ALL_CAMPAINGS_DELIVERY($offset: Int, $limit: Int) {
  campaigns(offset: $offset, limit: $limit) {
    campaign_id
    campaign_name
    advertiser {
      account_id
      account_name
    }
    sites {
      site_id
      site_name
    }
  }
}`

export const GET_ALL_CAMPAINGS_DELIVERY = gql` 
query Campaign($offset: Int, $limit: Int) {
  campaigns(offset: $offset, limit: $limit) {
    campaign_id
    campaign_name
    advertiser {
      account_id
      account_name
    }
    sites {
      site_id
      site_name
      channels {
        channel_id
        channel_descr
        placements {
          placement_id
          placement_name
          creatives {
            creative_id
            creative_group {
              creative_group_id
              group_name
              campaign_id
            }
          }
        }
      }
    }
  }
}`