import apollo from "@/plugins/apollo";
import { GET_SITES } from "@/graphql/Dashboard/Sites";
import { COUNT_CAMPAINGS } from "@/graphql/Dashboard/CountCampaigns";
import { GET_ALL_CAMPAINGS, GET_ALL_CAMPAINGS_COST, GET_ALL_CAMPAINGS_DELIVERY_DAY,GET_ALL_CAMPAINGS_DELIVERY } from "@/graphql/Dashboard/AllCampaigns";

const getSitesData = async (variables) => {
  const response = await apollo.query({
    query: GET_SITES,
    variables,
  });
  return response.data.sites;
};

const getCountCampaigns = async (variables) => {
  const response = await apollo.query({
    query: COUNT_CAMPAINGS,
    variables,
  });
  return response.data.count_campaigns.count;
};

const getAllCampaignsData = async (variables) => {
  const response = await apollo.query({
    query: GET_ALL_CAMPAINGS,
    variables,
  });
  return response.data.campaigns;
};

const getAllCampaignsCost = async (variables) => {
  const response = await apollo.query({
    query: GET_ALL_CAMPAINGS_COST,
    variables,
  });
  return response.data.campaigns;
};

const getAllCampaignsDeliveryDay = async (variables) => {
  const response = await apollo.query({
    query: GET_ALL_CAMPAINGS_DELIVERY,
    variables,
  });
  return response.data.campaigns;
};

const getAllCampaignsDelivery = async (variables) => {
  const response = await apollo.query({
    query: GET_ALL_CAMPAINGS_DELIVERY,
    variables,
  });
  return response.data.campaigns;
};

export { getSitesData, getCountCampaigns, getAllCampaignsData, getAllCampaignsCost, getAllCampaignsDeliveryDay, getAllCampaignsDelivery };
